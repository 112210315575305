import React from "react"
import Layout from '../Layout'
import Hero from '../Hero'
import Partners from '../Partners'
import Contact from '../Contact'
import DescriptionMessage from '../DescriptionMessage'
import VirtualRockfest from '../VirtualRockfest'
import Provider from '../Lang'
import Faq from '../Faq'

const Index = ({ locale }) => (
  <Provider locale={locale}>
    <Layout>
      <Hero />
      <Contact />
    </Layout>
  </Provider>
)

export default Index
