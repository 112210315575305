import React from 'react'

const Footer = () => (
  <div className='columns site-footer'>
    <div className='column'>
      <p>Montebello Rock © {new Date().getFullYear()}</p>
    </div>
  </div>
)

export default Footer
