import React from 'react'
import rehypeReact from "rehype-react"
import Pane from './Pane'

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { "pane": Pane }
}).Compiler

export default renderAst
