import React from 'react'
import * as PartnerImages from '../images/partners'
import SectionTitle from './SectionTitle'
import { Consumer } from './Lang'

const PARTNERS = [
  [
    { image: PartnerImages.Budweiser, href: "https://budweiser.ca" },
    { image: PartnerImages.Redbull, href: "https://redbull.com" },
    { image: PartnerImages.Rev, href: "https://groupegeloso.com/produit/rev" },
  ]
]

const renderTitle = locale => locale === 'en'
  ? 'Partners'
  : 'Partenaires'

const Partners = () => (
  <>
    <SectionTitle>
      <Consumer>{renderTitle}</Consumer>
    </SectionTitle>
    {PARTNERS.map(partners => (
      <div className='columns is-centered'>
        {partners.map(partner => (
          <div className='column is-2'>
            <a href={partner.href}>
              <img src={partner.image} target='_blank' />
            </a>
          </div>
        ))}
      </div>
    ))}
  </>
)

export default Partners
