import React from 'react'
import { Link } from 'gatsby'
import Header from './Header'
import '../styles/layout.scss'
import Footer from './Footer'
import SEO from './SEO'
import { Consumer } from './Lang'

const Layout = ({ children }) => (
  <div className="container">
    <SEO />
    <Header>
      <Consumer>
        {locale => (
          locale === 'en'
            ? <Link className="navbar-item" to="/">Français</Link>
            : <Link className="navbar-item" to="/en">English</Link>
        )}
      </Consumer>
    </Header>
    {children}
    <Footer />
  </div>
)

export default Layout
