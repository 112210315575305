import React from 'react'

const toTimestamp = date => Date.parse(date)

const getDiff = (now, target) => (toTimestamp(target) - toTimestamp(now)) / 1000

const SECONDS = 1
const SECONDS_IN_MINUTE = 60 * SECONDS
const SECONDS_IN_HOUR = 60 * SECONDS_IN_MINUTE
const SECONDS_IN_DAY = 24 * SECONDS_IN_HOUR
const SECONDS_IN_YEAR = 365 * SECONDS_IN_DAY

const PARTS = {
  years: SECONDS_IN_YEAR,
  days: SECONDS_IN_DAY,
  hours: SECONDS_IN_HOUR,
  minutes: SECONDS_IN_MINUTE,
  seconds: SECONDS
}

const getParts = (timestamp) => {
  const [, output] = Object.entries(PARTS).reduce(([diff, currentParts], [part, modulo]) => {
    const value = Math.floor(diff / modulo)
    return [
      diff - (modulo * value),
      {
        ...currentParts,
        [part]: value
      }
    ]
  }, [timestamp, {}])

  return output
}

const useTickEffect = (onTick, deps) => {
  React.useEffect(() => { setTimeout(onTick, 1000) }, deps)
}

const useCountdown = (target) => {
  const [date, setDate] = React.useState(new Date())
  const diff = getDiff(date, target)
  const { days, hours, minutes, seconds } = getParts(diff)

  useTickEffect(() => { setDate(new Date()) }, [date])

  return { days, hours, minutes, seconds }
}

const Countdown = ({ children, target }) => {
  const parts = useCountdown(target)

  return children(parts)
}

const StoppedCountdown = ({ children }) => children({
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
})

export default StoppedCountdown
