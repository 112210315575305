import React from 'react'

const Pane = ({ title, children }) => {
  const [visible, setVisible] = React.useState(false)

  return (
    <>
      <h3 className={visible ? 'visible' : ''} onClick={() => setVisible(v => !v)}>{title}</h3>
      {visible && <p>{children}</p>}
    </>
  )
}

export default Pane
