import React from 'react'
import SectionTitle from './SectionTitle'
import { Consumer } from './Lang'

const renderTitle = locale => locale === 'en'
  ? 'Contact'
  : 'Contact'

const INFO_EMAIL = 'info@montebellorock.com'

const PRESS_EMAIL = 'medias@montebellorock.com'
const PRESS_NAME = PRESS_EMAIL

const generalQuestion = locale => locale === 'en'
  ? 'General questions'
  : 'Questions générales'

const pressRequests = locale => locale === 'en'
  ? 'Press requests'
  : 'Demandes médias'

const Contact = () => (
  <div className='columns'>
    <Consumer>
      {locale => (
        <div className='column'>
          <SectionTitle>{renderTitle(locale)}</SectionTitle>
          <ul className='contacts'>
            <li>
              {generalQuestion(locale)}: <a href={`mailto:${INFO_EMAIL}`}>{INFO_EMAIL}</a>
            </li>
            <li>
              {pressRequests(locale)}: <a href={`mailto:${PRESS_EMAIL}`}>{PRESS_NAME}</a>
            </li>
          </ul>
        </div>
      )}
    </Consumer>
  </div>
)

export default Contact
