import React from 'react'

const Context = React.createContext('fr')

const Provider = ({ locale, children }) => (
  <Context.Provider value={locale}>
    {children}
  </Context.Provider>
)

export const Consumer = Context.Consumer

export const useLocale = () => React.useContext(Context)

export default Provider
