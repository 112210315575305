import React from 'react'
import { Link } from 'gatsby'
import Newsletter from './Newsletter'
import Countdown from './Countdown'
import { useLocale } from './Lang'

const TARGET = new Date(2020, 7, 24)

const numberLabel = (type) => {
  switch(type) {
    case 'days': return 'Jrs.'
    case 'hours': return 'Hrs.'
    case 'minutes': return 'Min.'
    default: return 'Sec.'
  }
}

const NAV = [
  { url: 'https://instagram.com/montebellorockofficial', label: () => 'IG' },
  { url: 'https://facebook.com/montebellorock', label: () => 'FB' },
  { url: 'https://montebellorock.bandcamp.com/merch', label: locale => locale === 'en' ? 'SHOP' : 'BOUTIQUE' },
]

const Header = ({ children }) => {
  const locale = useLocale()
  const [isActive, setIsActive] = React.useState(false)

  return (
    <div className='columns'>
      <div className='column'>
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <a role="button" className={['navbar-burger', 'burger', isActive ? 'is-active' : ''].join(' ')} aria-label="menu" aria-expanded="false" onClick={() => setIsActive(!isActive)}>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
          <div className={['navbar-menu', isActive ? 'is-active' : ''].join(' ')}>
            <div className="navbar-start">
              {children}
              {NAV.map((nav, index) => (
                <a className="navbar-item" href={nav.url} target="_blank">{nav.label(locale)}</a>
              ))}
              <div className="navbar-item">
                <Newsletter />
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div className='column countdown'>
        <Countdown target={TARGET}>
          {parts => Object.entries(parts).map(([name, value]) => (
            <div className="count number">
              {value}
              <span className="number-label">{numberLabel(name)}</span>
            </div>
          ))}
        </Countdown>
      </div>
    </div>
  )
}

export default Header
