import React from 'react'

const Newsletter = () => (
  <form className="field has-addons" method="post" action="https://ymlp.com/subscribe.php?id=geswsqsgmgj">
    <div className="control">
      <input className="input is-small" type="text" placeholder="Abonnement infolettre" name="YMP0" />
    </div>
    <div className="control">
      <button className="button is-info is-small">OK</button>
    </div>
  </form>
)

export default Newsletter
