import React from 'react'
import DateImage from '../images/dates.png'
import TicketsFr from '../images/tickets.fr.png'
import TicketsEn from '../images/tickets.en.png'
import { Consumer } from './Lang'

const TICKET_IMAGES = {
  fr: TicketsFr,
  en: TicketsEn
}

const Hero = () => (
  <div className="columns hero-banner">
    <div className="column has-text-centered">
      <img src={DateImage} />
    </div>
  </div>
)

export default Hero
